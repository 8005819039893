@font-face {
	font-family: 'Hind';
	font-style: normal;
	font-weight: 300;
	src:	local('Hind Light'), local('Hind-Light'),
			url('hind-v10-latin-ext_latin-300.a86b0b03.woff2') format('woff2'),
			url('hind-v10-latin-ext_latin-300.50855b44.woff') format('woff');
}

@font-face {
	font-family: 'Hind';
	font-style: normal;
	font-weight: 400;
	src: 	local('Hind Regular'), local('Hind-Regular'),
			url('hind-v10-latin-ext_latin-regular.bc132954.woff2') format('woff2'),
			url('hind-v10-latin-ext_latin-regular.ed92825d.woff') format('woff');
}

@font-face {
	font-family: 'Hind';
	font-style: normal;
	font-weight: 600;
	src: 	local('Hind SemiBold'), local('Hind-SemiBold'),
			url('hind-v10-latin-ext_latin-600.41e6fe79.woff2') format('woff2'),
			url('hind-v10-latin-ext_latin-600.3bc6cbf7.woff') format('woff');
}

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src:	local('Material Icons'),
	  		local('MaterialIcons-Regular'),
	  		url('material-icons-regular.49e147fe.woff2') format('woff2'),
	  		url('material-icons-regular.ac7f3b02.woff') format('woff');
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;  /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;

	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: 'liga';
}

/* Rules for sizing the icon. */

.material-icons.md-18 { font-size: 18px; }

.material-icons.md-24 { font-size: 24px; }

.material-icons.md-36 { font-size: 36px; }

.material-icons.md-48 { font-size: 48px; }

/* Rules for using icons as black on a light background. */

.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }

.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */

.material-icons.md-light { color: rgba(255, 255, 255, 1); }

.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }


div[data-v-1c2bbe] {
	height: 100%;
	display: flex;
	flex-direction: column;
}
ul[data-v-1c2bbe] {
	text-align: center;
	background-color: var(--theme-menu-background);
}
ul.main-nav[data-v-1c2bbe] {
	flex-grow: 1;
}
li[data-v-1c2bbe] {
	height: 50px;
	box-sizing: border-box;
}
li i.material-icons[data-v-1c2bbe] {
	color: var(--theme-foreground-against-accent);
	line-height: 50px;
}
.selected[data-v-1c2bbe] {
	background-color: var(--theme-accent);
}


.container[data-v-354d13] {
	position: absolute;
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}
img[data-v-354d13] {
	visibility: hidden;
	border-radius: 5px;
}


.player[data-v-08da49] {
	padding: 40px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}
audio[data-v-08da49] {
	display: none;
}
.art[data-v-08da49] {
	width: 120px;
	height: 120px;
	position: relative;
}
.missing-art[data-v-08da49] {
	height: 100%;
	border-radius: 5px;
	background: repeating-linear-gradient(-45deg, var(--theme-background-muted), var(--theme-background-muted) 8px, var(--theme-background) 8px, var(--theme-background) 16px);
}
.currentTrack[data-v-08da49] {
	flex-grow: 1;
	padding-left: 20px;
}
.controls[data-v-08da49] {
	margin-right: 20px;
	width: 120px;
	cursor: default;
}
.controls .playback[data-v-08da49] {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
}
.control[data-v-08da49] {
	border-radius: 50%;
	border: 1px solid var(--theme-border);
	text-align: center;
}
.control.previous[data-v-08da49],
.control.next[data-v-08da49] {
	width: 28px;
	height: 28px;
	line-height: 28px;
	padding-top: 4px;
	box-sizing: border-box;
}
.control.play[data-v-08da49],
.control.pause[data-v-08da49] {
	padding-top: 6px;
	width: 40px;
	height: 40px;
	line-height: 40px;
	box-sizing: border-box;
}
.volume[data-v-08da49] {
	margin-left: -4px;
	display: flex;
	flex-flow: row nowrap;
}
.volume .bar[data-v-08da49] {
	flex-grow: 1;
	background-color: var(--theme-foreground-muted);
	height: 10px;
	margin: 7px 0;
	border-radius: 3px;
}
.volume .fill[data-v-08da49] {
	height: 100%;
	max-width: 100%;
	background-color: var(--theme-accent);
	border-radius: 3px;
}
.trackInfo .primary[data-v-08da49] {
	font-weight: 600;
	margin-bottom: -5px;
}
.trackInfo .secondary[data-v-08da49],
.trackDuration[data-v-08da49] {
	font-weight: 300;
	font-size: 0.875rem;
}
.seekBar[data-v-08da49] {
	width: 100%;
	background-color: var(--theme-foreground-muted);
	height: 10px;
	margin: 6px 0;
	border-radius: 3px;
}
.seekBar .fill[data-v-08da49] {
	height: 100%;
	width: 0;
	max-width: 100%;
	background-color: var(--theme-accent);
	border-radius: 3px;
}
.seekBar .head[data-v-08da49] {
	width: 16px;
	height: 16px;
	position: relative;
	top: -14px;
	margin-left: -9px;
	background-color: var(--theme-background);
	border: 1px solid var(--theme-border);
	border-radius: 3px;
}
.controls[data-v-08da49],
.art img[data-v-08da49],
.currentTrack[data-v-08da49] {
	animation-duration: 250ms;
	animation-name: fadein-08da49;
}
@keyframes fadein-08da49 {
from {
		margin-top: 100px;
		transform: scale(0);
		opacity: 0;
}
to {
		margin-top: 0;
		transform: scale(1);
		opacity: 1;
}
}


button[data-v-4b3c70] {
	width: 95px;
	height: 32px;
	padding-left: 0;
	padding-right: 0;
}
button.success[data-v-4b3c70] {
	transition: all 250ms ease-in-out;
	background-color: var(--theme-good);
	border: 0;
}
button.failure[data-v-4b3c70] {
	transition: all 250ms ease-in-out;
	background-color: var(--theme-bad);
	border: 0;
}
button.success *[data-v-4b3c70] {
	color: var(--theme-foreground-against-good);
}
button.failure *[data-v-4b3c70] {
	color: var(--theme-foreground-against-bad);
}
.status[data-v-4b3c70] {
	text-align: center;
	height: 100%;
	width: 100%;
	position: relative;
	overflow: hidden;
}
.status span[data-v-4b3c70] {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
}
.tick-in[data-v-4b3c70] {
	animation: in-4b3c70 250ms ease-in-out;
	animation-fill-mode: forwards;
}
.tick-out[data-v-4b3c70] {
	animation: out-4b3c70 250ms ease-in-out;
	animation-fill-mode: forwards;
}
.snap-out[data-v-4b3c70] {
	animation: out-4b3c70 0ms;
	animation-fill-mode: forwards;
}
@keyframes in-4b3c70 {
from {
		top: 40px;
}
to {
		top: 0px;
}
}
@keyframes out-4b3c70 {
from {
		top: 0px;
}
to {
		top: -40px;
}
}


.popup[data-v-effb7f] {
	cursor: default;
	position: absolute;
	width: 400px;
	background-color: var(--theme-background);
	border: 1px solid var(--theme-border-muted);
	border-bottom: 2px solid var(--theme-accent);
	animation: 0.15s ease-out 0s 1 intro-effb7f;
}
.close[data-v-effb7f] {
	cursor: pointer;
	position: absolute;
	top: 5px;
	right: 5px;
}
form[data-v-effb7f] {
	padding: 20px;
}
input[data-v-effb7f] {
	width: inherit;
	margin-left: 10px;
}
label[data-v-effb7f] {
	margin-bottom: 15px;
}
@keyframes intro-effb7f {
0% {
		transform: translateX(-50px);
		opacity: 0;
}
100% {
		transform: translateX(0);
		opacity: 1;
}
}


.paneHeader[data-v-91fee7] {
	overflow: visible !important;
	padding-right: 30px;
}
.paneContent[data-v-91fee7] {
	padding: 0 !important;
	overflow-anchor: none;
}
.playlistDetails[data-v-91fee7] {
	display: flex;
	min-height: 0;
}
.playlistDetails .save[data-v-91fee7],
.playlistDetails .delete[data-v-91fee7],
.playlistDetails .shuffle[data-v-91fee7] {
	cursor: pointer;
	padding-right: 8px;
}
.playlistDetails .shuffle[data-v-91fee7] {
	padding-left: 8px;
	border-left: 1px solid var(--theme-border-muted);
}
.playlistDetails span.playbackOrder[data-v-91fee7] {
	color: var(--theme-foreground-muted);
	font-size: 0.875rem;
	align-self: center;
}
.playlistDetails .totalDuration[data-v-91fee7] {
	flex-grow: 1;
	text-align: right;
	color: var(--theme-foreground);
	font-size: 0.875rem;
	align-self: flex-start;
	position: relative; /*for dynamic offsetting compensating scrollbar*/
}
tr:not(:hover) .remove[data-v-91fee7] {
	color: transparent;
}
.remove[data-v-91fee7] {
	cursor: pointer;
}
table[data-v-91fee7] {
	width: 100%;
	border-spacing: 0;
}
tr[data-v-91fee7] {
	height: 30px; /*Used in JS*/
	cursor: default;
	white-space: nowrap;
}
tr[data-v-91fee7]:nth-child(2n) {
	background-color: var(--theme-background-muted);
}
td[data-v-91fee7] {
	padding-bottom: 3px;
	vertical-align: bottom;
	font-size: 0.8125rem;
}
.remove[data-v-91fee7],
td.nowPlaying[data-v-91fee7] {
	width: 25px;
	text-align: center;
}
td.text[data-v-91fee7] {
	max-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-right: 30px;
}
td.song .trackArtist[data-v-91fee7] {
	color: var(--theme-foreground-muted);
}
td.duration[data-v-91fee7] {
	text-align: right;
}
tr.nowPlaying td.song .trackArtist[data-v-91fee7] {
	color: var(--theme-foreground-against-accent);
	opacity: 0.65;
}
tr.nowPlaying td[data-v-91fee7],
tr.nowPlaying td *[data-v-91fee7] {
	color: var(--theme-foreground-against-accent);
	font-weight: 600;
	background-color: var(--theme-accent);
}
.material-icons.nowPlaying[data-v-91fee7] {
	vertical-align: middle;
	padding-bottom: 2px;
}
.help[data-v-91fee7] {
	margin-top: 50px;
}


.app {
	display: flex;
	height: 100%;
}
.sidebar {
	width: 50px;
	flex-shrink: 0;
}
.left-pane {
	height: 100%;
	flex-shrink: 0;
	width: max(550px, min(900px, calc(40% - 50px)));
}
.right-pane {
	height: 100%;
	flex-grow: 1;
	z-index: 1;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	border-left: 1px solid var(--theme-border-muted);
}
.pane {
	height: 100%;
}
.player {
	height: 160px;
	box-sizing: border-box;
	border-top: 1px solid var(--theme-border-muted);
}
.pane,
.player {
	overflow-x: hidden;
	background-color: var(--theme-background);
}
.paneHeader {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100px;
	z-index: 1;
	box-sizing: border-box;
	padding-left: 50px;
	padding-right: 50px;
	padding-top: 20px;
	padding-bottom: 10px;
	white-space: nowrap;
	overflow: hidden;
	border-bottom: 1px solid var(--theme-border-muted);
}
.paneContent {
	width: 100%;
	height: calc(100% - 100px);
	padding-top: 50px;
	padding-left: 50px;
	padding-right: 50px;
	overflow-x: hidden;
	overflow-y: auto;
	box-sizing: border-box;
}
.viewActions {
	margin-bottom: 40px;
	font-size: 0;
}
.viewActions .header {
	line-height: 1;
	margin-bottom: 5px;
	font-size: 1.5rem;
}
.viewActions .subHeader {
	line-height: 1;
	font-size: 1.25rem;
	margin-bottom: 5px;
	color: var(--theme-foreground-muted);
}
.viewActions button {
	display: inline;
}


.authForm[data-v-eaf370] {
	height: 100%;
	margin: auto;
	width: 25%;
}
.logo[data-v-eaf370] {
	width: 100%;
	margin-bottom: 70px;
}
.content[data-v-eaf370] {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: stretch;
	height: 90%;
}
form[data-v-eaf370] {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: stretch;
}
input[data-v-eaf370] {
	width: inherit;
	display: block;
	margin: 5px 0;
	font-size: 1.5rem;
}
input[type="submit"][data-v-eaf370] {
	align-self: flex-end;
	font-size: 1.25rem;
	margin-top: 15px;
}
input[type="text"][data-v-eaf370],
input[type="password"][data-v-eaf370],
.tip[data-v-eaf370] {
	padding-left: 10px;
}
input[type="text"][data-v-eaf370],
input[type="password"][data-v-eaf370] {
	border: 0;
	box-sizing: content-box;
	border-bottom: 1px solid var(--theme-border);
}
.tip[data-v-eaf370] {
	/*Exclude from layout so the form doesn't move when this appears*/
	height: 0;
	overflow: visible;
}


ul[data-v-24d551] {
	display: flex;
	font-size: 0.875rem;
	max-width: 100%;
}
li[data-v-24d551] {
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 600;
	color: var(--theme-foreground-against-accent);
	background-color: var(--theme-accent);
	cursor: pointer;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 1px;
	border-radius: 5px;
	margin-right: 5px;
}
li[data-v-24d551]:last-child {
	margin-right: 0;
}


.details[data-v-6eca76] {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	margin-bottom: 50px;
}
.coverArt[data-v-6eca76] {
	flex-shrink: 0;
	width: 40%;
	display: flex;
	justify-content: start;
	align-items: start;
	margin-bottom: 30px;
}
img[data-v-6eca76] {
	width: 100%;
	border-radius: 5px;
}
.trackList[data-v-6eca76] {
	flex-grow: 1;
	min-width: 0;
	cursor: default;
	margin-left: 20px;
}
.discNumber[data-v-6eca76] {
	font-weight: 600;
	margin-bottom: 10px;
	padding: 10px;
	padding-top: 5px;
	padding-bottom: 2px;
	color: var(--theme-background);
	background-color: var(--theme-foreground-muted);
	border-radius: 3px;
}
li:not(:first-child) .discNumber[data-v-6eca76] {
	margin-top: 20px;
}
.discContent[data-v-6eca76] {
	padding-left: 25px;
}
li.song[data-v-6eca76] {
	padding-left: 10px;
	padding-top: 6px;
	list-style-type: decimal;
	list-style-position: outside;
}
.songName[data-v-6eca76] {
	width: 100%;
	display: inline-block;
	vertical-align: text-top;
	padding-bottom: 3px;
	border-bottom: 1px solid var(--theme-border-muted);
	overflow: hidden;
	text-overflow: ellipsis;
}
li.song:last-child .songName[data-v-6eca76] {
	border-bottom: none;
}
li.song.no-track-number[data-v-6eca76] {
	list-style-type: none;
}
.trackArtist[data-v-6eca76] {
	color: var(--theme-foreground-muted);
}
li[data-v-6eca76]:first-child {
	padding-top: 0;
}
li[data-v-6eca76]:last-child {
	border-bottom: 0;
}


ul[data-v-dca80f] {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}
.album[data-v-dca80f] {
	font-size: 0;
	margin-bottom: 20px;
	cursor: default;
	width: 23.5%;
	margin-left: 1%;
	margin-right: 1%;
}
.album[data-v-dca80f]:nth-child(4n + 1) {
	margin-left: 0;
}
.album[data-v-dca80f]:nth-child(4n) {
	margin-right: 0;
}
.cover[data-v-dca80f] {
	width: 100%;
	position: relative;
}
.cover[data-v-dca80f]:after {
	/*Hack to make this element stay square when its width changes*/
	content: "";
	display: block;
	padding-bottom: 100%;
}
.details[data-v-dca80f] {
	padding: 10px 0;
	width: 100%;
}
.details .title[data-v-dca80f] {
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 10px;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.25;
}
.details .artist[data-v-dca80f] {
	margin-bottom: -5px;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 10px;
	font-size: 0.875rem;
}
.details .year[data-v-dca80f] {
	font-size: 0.875rem;
	color: var(--theme-foreground-muted);
}


.explorerView[data-v-5f93ad] {
	margin-bottom: 50px;
}
.directory .material-icons[data-v-5f93ad] {
	vertical-align: bottom;
	margin-right: 5px;
	padding-bottom: 3px;
}
.directory[data-v-5f93ad],
.song[data-v-5f93ad] {
	cursor: default;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}


ul[data-v-1657e3] {
	margin-bottom: 40px;
}
li[data-v-1657e3]:not(:last-child) {
	border-bottom: 1px solid var(--theme-border-muted);
}
li[data-v-1657e3]:not(:first-child) {
	padding-top: 8px;
}
li[data-v-1657e3] {
	cursor: pointer;
	padding-bottom: 6px;
}
.help i.save[data-v-1657e3] {
	position: relative;
	top: 4px;
}


.more[data-v-b138d0] {
	cursor: pointer;
	height: 20px;
}
.more span[data-v-b138d0] {
	padding-left: 4px;
	font-size: 0.875rem;
	vertical-align: top;
}


.more[data-v-12e639] {
	cursor: pointer;
	height: 20px;
}
.more span[data-v-12e639] {
	padding-left: 4px;
	font-size: 0.875rem;
	vertical-align: top;
}


form[data-v-94bbce] {
	height: 22px;
	display: flex;
}
input[data-v-94bbce],
button[data-v-94bbce] {
	padding-top: 0;
	padding-bottom: 0;
	margin-bottom: 0;
}
input[data-v-94bbce] {
	width: 40%;
	font-size: 0.812rem;
}
button[data-v-94bbce] {
	margin-left: 10px;
}


.setupContainer[data-v-382214] {
	width: 50%;
	height: 100%;
	display: flex;
	flex-wrap: nowrap;
	justify-content: stretch;
	align-items: flex-start;
	align-content: flex-start;

	margin: auto;
	padding-top: 10%;
	box-sizing: border-box;
}
.step[data-v-382214] {
	flex-grow: 1;
	flex-shrink: 1;
	border-right: 2px solid var(--theme-accent);
	padding-left: 40px;
	padding-right: 40px;
}
.logo[data-v-382214] {
	width: 15%;
	padding-top: 10px;
	padding-right: 40px;
	flex-grow: 0;
	flex-shrink: 0;
}
img[data-v-382214] {
	width: 100%;
}


span[data-v-b79049] {
	cursor: pointer;
	color: var(--theme-foreground-muted);
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 2px;
	font-size: 1rem;
	border: 1px solid var(--theme-background-muted);
	border-bottom: 1px solid var(--theme-border-muted);
	background-color: var(--theme-background-muted);
}
span[data-v-b79049]:first-child {
	border-radius: 3px 0 0 0;
}
span[data-v-b79049]:last-child {
	border-radius: 0 3px 0 0;
}
span.selected[data-v-b79049] {
	color: var(--theme-accent);
	font-weight: 400;
	border: 1px solid var(--theme-border-muted);
	border-bottom: 1px solid var(--theme-background);
	border-radius: 3px 3px 0 0;
	background-color: var(--theme-background);
}


.paneHeader[data-v-723aa8] {
	padding-bottom: 0px;
	justify-content: space-between;
	overflow: visible;
}


.sleep_duration label[data-v-fad9ea] {
	display: inline;
}
.sleep_duration input[data-v-fad9ea] {
	width: 50px;
	text-align: center;
}


a[data-v-bdc4af] {
	text-decoration: underline;
	color: var(--theme-accent);
}
.code[data-v-bdc4af] {
	font-family: "Courier New", "sans-serif";
	color: inherit;
}


a[data-v-e031df] {
	text-decoration: underline;
	color: var(--theme-accent);
}
td.accent-color[data-v-e031df] {
	background-color: var(--theme-accent);
}
input[type="color"][data-v-e031df] {
	opacity: 0;
}


div.username[data-v-91a487] {
	padding-top: 8px;
	padding-left: 10px;
	background-color: var(--theme-accent);
	border-radius: 5px 5px 0px 0px;
}
div.username i[data-v-91a487] {
	color: var(--theme-foreground-against-accent);
}
div.username span[data-v-91a487] {
	vertical-align: top;
	margin-left: 8px;
	position: relative;
	top: 1px;
	color: var(--theme-foreground-against-accent);
	font-weight: 600;
}
div.details[data-v-91a487] {
	padding: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 20px;
	border-radius: 0px 0px 5px 5px;
	border: 1px solid var(--theme-border-muted);
	border-top: 0;
}
div.actions[data-v-91a487] {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}
.admin label[data-v-91a487] {
	font-weight: 300;
}
input[type="checkbox"][data-v-91a487] {
	margin-right: 5px;
	width: auto;
	position: relative;
	top: 1px;
}
label.admin[data-v-91a487] {
	display: inline;
}
form.password-editing[data-v-91a487] {
	display: flex;
}
form.password-editing input[data-v-91a487] {
	margin-right: 10px;
}


ul[data-v-468431] {
	width: calc(70% + 40px);
}
button.submit[data-v-468431] {
	margin-top: 10px;
}


div[data-v-c9df6d] {
	height: 100%;
}

/*# sourceMappingURL=index.0eb5d501.css.map */
